import { useAuth } from "@/store/auth/hooks";
import React from "react";
import DashboardChip from "../dashboardChip";
import LoginChip from "../loginChip";

const ProfileChip = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <DashboardChip /> : <LoginChip />;
};

export default React.memo(ProfileChip);
