import { useParams } from "react-router";

type RouteParams = {
  specialityId: string;
  doctorId: string;
  locale: string;
  appointmentId: string;
  userId: string;
  insuranceId: string;
};

export const useRouteParams = () => {
  const param = useParams<RouteParams>();

  return {
    specialityId: param.specialityId,
    doctorId: Number(param.doctorId),
    locale: param.locale,
    appointmentId: Number(param.appointmentId),
    userId: Number(param.userId),
    insuranceId: Number(param.insuranceId)
  };
};
