import MainHeader from "@/layout/header/mainHeader";
import { IonContent, IonPage } from "@ionic/react";
import React, { FC, PropsWithChildren } from "react";

const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <IonPage>
      <MainHeader />
      <IonContent>{children}</IonContent>
    </IonPage>
  );
};

export default React.memo(MainLayout);
