import { Box, Icon, SelectAccountActions, Span } from "@/components";
import { getStyledElement, getMediaStyles } from "@/helpers";
import { useRouter } from "@/hooks";
import { IonItem, IonSelectOption } from "@ionic/react";
import { person } from "ionicons/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const LoginChipItem = getStyledElement(IonItem)({
  styles: ({ theme }) => ({
    "--border-radius": "20px",
    "--min-height": 0,
    "--show-full-highlight": 0,
    "--border-width": 0,
    "--background-hover-opacity": 0,
    "--inner-border-width": "0",
    "--padding-top": "8px",
    "--padding-bottom": "8px",
    ...getMediaStyles({
      "--background": [theme.colors.lightPrx, theme.colors.secondaryPrx],
      "--color": [theme.colors.primaryPrx, theme.colors.whitePrx]
    })
  })
});

const LoginChip = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const changeNavigation = (event: CustomEvent<{ value: string }>) => {
    if (event.detail.value === router.routeInfo.pathname) {
      return;
    }

    router.push(event.detail.value);
  };

  return (
    <LoginChipItem>
      <SelectAccountActions
        onIonChange={changeNavigation}
        interface="popover"
        value={router.routeInfo.pathname}
      >
        <Box slot="label" variant="flexRow" alignItems="center">
          <Icon color={["primary", "light"]} slot="start" icon={person} />
          <Span>{t("LOGIN")}</Span>
        </Box>
        <IonSelectOption value="login">{t("LOGIN")}</IonSelectOption>
        <IonSelectOption value="registration">
          {t("REGISTRATION")}
        </IonSelectOption>
      </SelectAccountActions>
    </LoginChipItem>
  );
};

export default React.memo(LoginChip);
