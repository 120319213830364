import queryString from "query-string";
import { useMemo } from "react";
import { useLocation } from "react-router";

type RouteQueryParams = {
  specialityId: string;
  doctorId: number;
  date: string;
  roomId: string;
  appointmentId: number;
};

export const useRouteQueryParams = (): RouteQueryParams => {
  const location = useLocation();
  const search = location.search || (location as any)?.location?.search;

  return useMemo(
    () =>
      queryString.parse(search, {
        parseBooleans: true,
        parseNumbers: true
      }) as RouteQueryParams,
    [search]
  );
};
