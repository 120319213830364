import {
  IonHeader,
  IonToolbar,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import { Icon } from "@/components";
import { getStyledElement } from "@/helpers";
import { useMedia, useRouter } from "@/hooks";
import { home, library } from "ionicons/icons";
import styled from "styled-components";
import { FaUserDoctor } from "react-icons/fa6";
import { IonSegmentCustomEvent, SegmentChangeEventDetail } from "@ionic/core";
import React, { useEffect, useState } from "react";

const Header = getStyledElement(IonHeader)({
  styles: ({ theme }) => ({
    boxShadow: "none",
    background: theme.colors.lightPrx,
    borderTop: "1px solid #EDEDED",
    "&.header-md::after": {
      content: "unset"
    }
  })
});

const Toolbar = getStyledElement(IonToolbar)({
  styles: () => ({
    "--background": "none",
    "--border-style": "none",
    "--padding-top": "0"
  })
});

const TabTitle = getStyledElement("span")({
  styles: () => ({
    margin: "5px 0",
    textTransform: "initial",
    fontSize: "12px"
  })
});

const DoctorsIcon = styled(FaUserDoctor)`
  font-size: 24px;
  margin: 0;
`;

const SegmentButton = styled(IonSegmentButton)`
  --indicator-height: 0;
  --padding-top: 10px;
`;

enum RouteName {
  Home = "home",
  Specialties = "specialities",
  Doctors = "doctors"
}

const TabsToolbar: React.FC = () => {
  const router = useRouter();
  const { isMobile } = useMedia();
  const [selectedSegment, setSelectedSegment] = useState("");

  const onChange = (event: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
    router.push(`${event.detail.value}`);
  };

  useEffect(() => {
    const segments = new Set([
      RouteName.Home,
      RouteName.Doctors,
      RouteName.Specialties
    ]);
    const [segment] = router.routeInfo.pathname.split("/").slice(2);

    if (segments.has(segment as RouteName)) setSelectedSegment(segment);
    else setSelectedSegment("");
  }, [router.routeInfo.pathname]);

  if (!isMobile) return null;

  return (
    <Header>
      <Toolbar>
        <IonSegment value={selectedSegment} onIonChange={onChange}>
          <SegmentButton value="home">
            <Icon m="0" icon={home} />
            <TabTitle>Acasă</TabTitle>
          </SegmentButton>
          <SegmentButton value="doctors">
            <DoctorsIcon />
            <TabTitle>Doctori</TabTitle>
          </SegmentButton>
          <SegmentButton value="specialities">
            <Icon m="0" icon={library} />
            <TabTitle>Specialități</TabTitle>
          </SegmentButton>
        </IonSegment>
      </Toolbar>
    </Header>
  );
};

export default React.memo(TabsToolbar);
