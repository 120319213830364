import { Api, AxiosClient } from "@/api";
import { HttpError } from "@/core/types";
import { useGetUser } from "@/store/user/hooks";
import { AxiosResponse } from "axios";
import { useShowHttpError } from "./useShowHttpError";

export const useDownload = () => {
  const showError = useShowHttpError();
  const { data: user } = useGetUser();

  const getFile = async (id: number) => {
    try {
      const { data, headers } = await AxiosClient.get<AxiosResponse>(
        Api.file(id),
        {
          params: user?.id ? { userId: user.id } : {},
          responseType: "arraybuffer"
        }
      );

      let filename = "no-name.txt";
      const contentDisposition = headers["content-disposition"];

      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      const blob = new Blob([data], { type: "application/octet-stream" });

      blob.stream();

      return { blob, filename };
    } catch (error) {
      const { message } = error as HttpError;

      showError(message);

      throw error;
    }
  };

  const downloadFile = async (fileId: number) => {
    const file = await getFile(fileId);

    if (!file) return false;

    const element = document.createElement("a");

    element.href = URL.createObjectURL(file.blob);
    element.download = file.filename;

    document.body.appendChild(element);
    element.click();

    return true;
  };

  return downloadFile;
};
