import { Api, AxiosClient } from "@/api";
import { useInvalidateDoctorsState } from "@/store/doctors/hooks/useInvalidateDoctorsState";
import { HttpError } from "@/core/types";
import { useToast } from "./useToast";
import { useShowHttpError } from "./useShowHttpError";

export const useUploadAvatar = () => {
  const invalidateDoctor = useInvalidateDoctorsState();
  const showError = useShowHttpError();
  const { showSuccess } = useToast();

  return async (file: File, userId: number) => {
    try {
      const formData = new FormData();

      formData.append("file", file);

      await AxiosClient.put(Api.userAvatar(userId), formData, {
        headers: {
          accept: "multipart/form-data",
          "Content-Type": "multipart/form-data"
        }
      });

      await invalidateDoctor();

      showSuccess({ message: "TOASTS.SAVED_DATA" });
    } catch (error) {
      const { message } = error as HttpError;

      showError(message);
    }
  };
};
