import { Api } from "@/api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { Patient } from "@/core/types";
import { GetPatients } from "@/core/types/getPatients";
import { List } from "@/core/types/list";
import { axiosBaseQuery } from "../baseQuary";

export const patientsApi = createApi({
  reducerPath: "Patients",
  baseQuery: axiosBaseQuery,
  tagTypes: ["Patients"],
  endpoints: (build) => ({
    getPacients: build.query<List<Patient>, GetPatients>({
      query: (params) => ({
        url: Api.pacients(),
        method: "get",
        params
      }),
      providesTags: (result) => {
        return result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Patients" as const,
                id
              })),
              { type: "Patients", id: "LIST" }
            ]
          : [{ type: "Patients", id: "LIST" }];
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.offset === 0) return newItems;

        return {
          items: [...currentCache.items, ...newItems.items],
          total: newItems.total
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      }
    }),
    getPatient: build.query<Patient, { id: number }>({
      query: ({ id }) => ({
        url: Api.pacient(id),
        method: "get"
      }),
      providesTags: (_, __, { id }) => [{ type: "Patients", id }]
    }),
    updatePatient: build.mutation<void, Partial<Patient> & { id: number }>({
      query: (patient) => ({
        url: Api.pacient(patient.id),
        method: "PUT",
        data: patient
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: "Patients", id: "LIST" },
        { type: "Patients", id }
      ]
    }),
    deletePacient: build.mutation<void, number>({
      query: (id) => ({
        url: Api.user(id),
        method: "DELETE"
      }),
      invalidatesTags: (_, __, id) => [
        { type: "Patients", id: "LIST" },
        { type: "Patients", id }
      ]
    })
  })
});
