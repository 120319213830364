import { AxiosClient, Api } from "@/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Tokens } from "@/core/types";
import { AxiosResponse } from "axios";
import { RegistrationData } from "../types";

export const registration = createAsyncThunk(
  "auth/registration",
  async (user: RegistrationData): Promise<Tokens> => {
    const { data } = await AxiosClient.post<AxiosResponse<Tokens>>(
      Api.registration(),
      user
    );

    return data;
  }
);
