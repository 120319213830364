import { getStyledElement, getMediaStyles } from "@/helpers";

export const Span = getStyledElement("span")({
  variants: {
    select: {
      userSelect: "all"
    }
  }
});

export const SpanRownded = getStyledElement("span")({
  styles: ({ theme }) => ({
    backgroundColor: theme.colors.primaryPrx,
    borderRadius: theme.borderRadius[20],
    color: theme.colors.whitePrx,
    whiteSpace: "nowrap",
    padding: `${theme.padding[6]} ${theme.padding[10]}`,
    ...getMediaStyles({
      fontSize: ["12px", "14px"]
    })
  })
});
