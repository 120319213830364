import { GetPatientsParams } from "@/core/types";
import { GetAppointmentsParams } from "@/core/types/api/getAppointmentsParams";
import { ListParams } from "@/core/types/listParams";
import { useAppDispatch, useAppSelector } from "../../hook";
import { paginationSlice } from "../reducer";

export function usePagination() {
  const dispatch = useAppDispatch();

  return {
    setAppointmentsParams: (params: Partial<ListParams>) =>
      dispatch(paginationSlice.actions.setAppointmentsParams(params)),
    resetAppointmentsParams: () =>
      dispatch(paginationSlice.actions.resetAppointmentsParams()),
    setDoctorsParams: (params: Partial<ListParams>) =>
      dispatch(paginationSlice.actions.setDoctorsParams(params)),
    resetDoctorsParams: () =>
      dispatch(paginationSlice.actions.resetDoctorsParams()),
    setPatientsParams: (params: Partial<GetPatientsParams>) =>
      dispatch(paginationSlice.actions.setPatientsParams(params)),
    resetPatientsParams: () =>
      dispatch(paginationSlice.actions.resetPatientsParams()),
    setAppointmentsAdminParams: (params: Partial<GetAppointmentsParams>) =>
      dispatch(paginationSlice.actions.setAppointmentsAdminParams(params)),
    resetAppointmentsAdminParams: () =>
      dispatch(paginationSlice.actions.resetAppointmentsAdminParams()),
    setAppointmentsDoctorParams: (params: Partial<GetAppointmentsParams>) =>
      dispatch(paginationSlice.actions.setAppointmentsDoctorParams(params)),
    resetAppointmentsDoctorParams: () =>
      dispatch(paginationSlice.actions.resetAppointmentsDoctorParams()),
    ...useAppSelector((state) => state.paginationReducer)
  };
}
