import { AxiosClient, Api } from "@/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Tokens } from "@/core/types";
import { AxiosResponse } from "axios";

export const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (): Promise<Tokens> => {
    const { data } = await AxiosClient.post<AxiosResponse<Tokens>>(
      Api.refresh()
    );

    return data;
  }
);
