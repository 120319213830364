import { Api } from "@/api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { MedicalInsurance, UpdateMedicalInsurance } from "@/core/types";
import { AddInsuranceFiles } from "@/core/types/api/addInsuranceFilesParams";
import { axiosBaseQuery } from "../baseQuary";

export const medicalInsuranceApi = createApi({
  reducerPath: "MedicalInsurance",
  baseQuery: axiosBaseQuery,
  tagTypes: ["MedicalInsurance"],
  endpoints: (build) => ({
    getMedicalInsurances: build.query<MedicalInsurance[], { userId: number }>({
      query: ({ userId }) => ({
        url: Api.medicalInsurances(userId),
        method: "get"
      }),
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: "MedicalInsurance" as const,
                id
              })),
              { type: "MedicalInsurance", id: "LIST" }
            ]
          : [{ type: "MedicalInsurance", id: "LIST" }];
      }
    }),
    getMedicalInsurance: build.query<
      MedicalInsurance,
      { userId: number; insuranceId: number }
    >({
      query: ({ userId, insuranceId }) => ({
        url: Api.medicalInsurance(userId, insuranceId),
        method: "get"
      }),
      providesTags: () => [{ type: "MedicalInsurance" }]
    }),
    createMedicalInsurance: build.mutation<
      MedicalInsurance,
      { userId: number }
    >({
      query: ({ userId }) => {
        return {
          url: Api.medicalInsurances(userId),
          method: "POST",
          data: {}
        };
      },
      invalidatesTags: [{ type: "MedicalInsurance" }]
    }),
    addMedicalInsuranceFiles: build.mutation<
      void,
      { userId: number; insuranceId: number } & AddInsuranceFiles
    >({
      query: ({ userId, insuranceId, ...body }) => {
        return {
          url: Api.medicalInsuranceFiles(userId, insuranceId),
          method: "PUT",
          data: body
        };
      },
      invalidatesTags: [{ type: "MedicalInsurance" }]
    }),
    updateMedicalInsurance: build.mutation<
      void,
      { userId: number; insuranceId: number } & UpdateMedicalInsurance
    >({
      query: ({ userId, insuranceId, ...data }) => {
        return {
          url: Api.medicalInsurance(userId, insuranceId),
          method: "PUT",
          data
        };
      },
      invalidatesTags: [{ type: "MedicalInsurance" }]
    }),
    deleteMedicalInsurance: build.mutation<
      void,
      { userId: number; insuranceId: number }
    >({
      query: ({ userId, insuranceId }) => ({
        url: Api.medicalInsurance(userId, insuranceId),
        method: "DELETE"
      }),
      invalidatesTags: () => [{ type: "MedicalInsurance" }]
    }),
    deleteMedicalInsuranceFile: build.mutation<void, { fileId: number }>({
      query: ({ fileId }) => ({
        url: Api.file(fileId),
        method: "DELETE"
      }),
      invalidatesTags: () => [{ type: "MedicalInsurance" }]
    })
  })
});
