import { Redirect, Route } from "react-router-dom";
import React, { Suspense } from "react";
import { IonProgressBar, IonRouterOutlet } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth/hooks";
import MainLayout from "@/layout/content/MainLayout";

const PaymentPage = React.lazy(() => import("../pages/payment"));
const DashboardPage = React.lazy(() => import("../pages/dashboard"));
const AppointmentPage = React.lazy(() => import("../pages/appointment"));
const RegistrationPage = React.lazy(() => import("../pages/registration"));
const LoginPage = React.lazy(() => import("../pages/auth"));
const DoctorsPage = React.lazy(() => import("../pages/doctors"));
const SpecialityPage = React.lazy(() => import("../pages/speciality"));
const SpecialitiesPage = React.lazy(() => import("../pages/specialities"));
const HomePage = React.lazy(() => import("../pages/home"));
const DoctorPage = React.lazy(() => import("../pages/doctor"));
const ConfirmPhonePage = React.lazy(() => import("../pages/confirmPhone"));
const ChangePasswordPage = React.lazy(() => import("../pages/ChangePassword"));

const ChangePasswordRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <ChangePasswordPage />
  </Suspense>
);

const HomeRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <HomePage />
  </Suspense>
);

const SpecialitiesRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <SpecialitiesPage />
  </Suspense>
);

const SpecialityRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <SpecialityPage />
  </Suspense>
);

const DoctorsRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <DoctorsPage />
  </Suspense>
);

const DoctorRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <DoctorPage />
  </Suspense>
);

const LoginRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <LoginPage />
  </Suspense>
);

const RegistrationRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <RegistrationPage />
  </Suspense>
);

const AppointmentRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <AppointmentPage />
  </Suspense>
);

const DashboardRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <DashboardPage />
  </Suspense>
);

const ConfirmPhoneRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <ConfirmPhonePage />
  </Suspense>
);

const PaymentRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <PaymentPage />
  </Suspense>
);

const Routes = () => {
  const { i18n } = useTranslation();
  const { isAuthenticated } = useAuth();

  return (
    <IonRouterOutlet id="main">
      <Route exact path="/">
        <Redirect exact to={`/${i18n.language}`} />
      </Route>
      <Route exact path="/:locale">
        <Redirect exact to={`/${i18n.language}/home`} />
      </Route>
      <Route exact path="/payment">
        <Redirect exact to={`/${i18n.language}/payment`} />
      </Route>

      <Route exact path="/:locale/home">
        <MainLayout>
          <HomeRoute />
        </MainLayout>
      </Route>
      <Route exact path="/:locale/appointment">
        <MainLayout>
          {isAuthenticated ? <AppointmentRoute /> : <LoginRoute />}
        </MainLayout>
      </Route>
      <Route path="/:locale/dashboard">
        <MainLayout>
          {isAuthenticated ? <DashboardRoute /> : <LoginRoute />}
        </MainLayout>
      </Route>
      <Route exact path="/:locale/specialities">
        <MainLayout>
          <SpecialitiesRoute />
        </MainLayout>
      </Route>
      <Route exact path="/:locale/specialities/:specialityId">
        <MainLayout>
          <SpecialityRoute />
        </MainLayout>
      </Route>
      <Route exact path="/:locale/doctors">
        <MainLayout>
          <DoctorsRoute />
        </MainLayout>
      </Route>
      <Route exact path="/:locale/doctors/:doctorId">
        <MainLayout>
          <DoctorRoute />
        </MainLayout>
      </Route>
      <Route exact path="/:locale/login">
        {isAuthenticated ? (
          <Redirect to={`${i18n.language}/home`} />
        ) : (
          <MainLayout>
            <LoginRoute />
          </MainLayout>
        )}
      </Route>
      <Route exact path="/:locale/registration">
        {isAuthenticated ? (
          <Redirect to={`${i18n.language}/home`} />
        ) : (
          <MainLayout>
            <RegistrationRoute />
          </MainLayout>
        )}
      </Route>
      <Route exact path="/:locale/confirm-phone">
        <MainLayout>
          {isAuthenticated ? <ConfirmPhoneRoute /> : <LoginRoute />}
        </MainLayout>
      </Route>
      <Route exact path="/:locale/change-password">
        <MainLayout>
          {isAuthenticated ? <ChangePasswordRoute /> : <LoginRoute />}
        </MainLayout>
      </Route>
      <Route exact path="/:locale/payment">
        <MainLayout>
          <PaymentRoute />
        </MainLayout>
      </Route>
    </IonRouterOutlet>
  );
};

export default React.memo(Routes);
