import { doctorsApi } from "../api";

export const {
  useGetDoctorsQuery,
  useLazyGetDoctorWorkingDaysQuery,
  useGetDoctorQuery,
  useUpdateDoctorMutation,
  useAddDoctorMutation,
  useDeleteDoctorMutation,
  useDeleteDoctorAvatarMutation
} = doctorsApi;
