import { Api, AxiosClient } from "@/api";
import { HttpError } from "@/core/types";
import { useShowHttpError } from "./useShowHttpError";

export const useUploadFile = () => {
  const showError = useShowHttpError();

  return async (file: File, params?: { userId: number }) => {
    try {
      const formData = new FormData();

      formData.append("file", file);

      const { data } = await AxiosClient.post(Api.files(), formData, {
        headers: {
          accept: "multipart/form-data",
          "Content-Type": "multipart/form-data"
        },
        params
      });

      return data as number;
    } catch (error) {
      const { message } = error as HttpError;

      showError(message);

      throw error;
    }
  };
};
