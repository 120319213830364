import { Roles } from "@/core/constants";
import { useGetUser } from "@/store/user/hooks";

export const useComponentByRole = <T, R, C>(
  clientComponent: T,
  doctorComponent: R,
  adminComponent: C
) => {
  const { data: user } = useGetUser();

  switch (user?.role) {
    case Roles.Doctor:
      return doctorComponent;
    case Roles.Client:
      return clientComponent;
    case Roles.Admin:
      return adminComponent;
    default:
      return null;
  }
};
