import { AxiosClient, Api } from "@/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Tokens } from "@/core/types";
import { AxiosResponse } from "axios";
import { LoginData } from "../types";

export const login = createAsyncThunk(
  "auth/login",
  async (user: LoginData): Promise<Tokens> => {
    const { data } = await AxiosClient.post<AxiosResponse<Tokens>>(
      Api.login(),
      user
    );

    return data;
  }
);
