import {
  ConfigureStoreOptions,
  combineReducers,
  configureStore
} from "@reduxjs/toolkit";
import { loadingBarReducer } from "react-redux-loading-bar";
import { authSlice } from "./auth/reducer";
import { doctorsApi } from "./doctors/api";
import { appointmentsApi } from "./appointments/api";
import { userApi } from "./user/api";
import { patientsApi } from "./patients/api";
import { specialtiesApi } from "./specialties/api";
import { medicalInsuranceApi } from "./medicalInsurance/api";
import { prescriptionApi } from "./prescription/api";
import { paginationSlice } from "./pagination/reducer";

const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  authReducer: authSlice.reducer,
  paginationReducer: paginationSlice.reducer,

  [doctorsApi.reducerPath]: doctorsApi.reducer,
  [appointmentsApi.reducerPath]: appointmentsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [patientsApi.reducerPath]: patientsApi.reducer,
  [specialtiesApi.reducerPath]: specialtiesApi.reducer,
  [medicalInsuranceApi.reducerPath]: medicalInsuranceApi.reducer,
  [prescriptionApi.reducerPath]: prescriptionApi.reducer
});

export const setupStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        doctorsApi.middleware,
        appointmentsApi.middleware,
        userApi.middleware,
        patientsApi.middleware,
        specialtiesApi.middleware,
        medicalInsuranceApi.middleware,
        prescriptionApi.middleware
      ),
    ...options
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
