import { useDeleteDoctorAvatarMutation } from "@/store/doctors/hooks";
import { useEffect } from "react";
import { useToast } from "./useToast";
import { useHttpErrorHandler } from "./useHttpErrorHandler";

export const useDeleteAvatar = () => {
  const [deleteAvatar, { error, isSuccess }] = useDeleteDoctorAvatarMutation();
  const { showSuccess } = useToast();

  useHttpErrorHandler(error);

  useEffect(() => {
    if (!isSuccess) return;

    showSuccess({ message: "TOASTS.SAVED_DATA" });
  }, [isSuccess]);

  return { deleteAvatar };
};
