/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { AppointmentStatus } from "@/core/constants";
import { GetPatientsParams } from "@/core/types";
import { GetAppointmentsParams } from "@/core/types/api/getAppointmentsParams";
import { ListParams } from "@/core/types/listParams";
import { dayjs } from "@/helpers";
import { createSlice } from "@reduxjs/toolkit";
import { PaginationState } from "./types";

const initialAppointmentsParams: GetAppointmentsParams = {
  offset: 0,
  limit: 15,
  statuses: [
    AppointmentStatus.WaitingForPayment,
    AppointmentStatus.PaymentInProgress,
    AppointmentStatus.Paid,
    AppointmentStatus.Done
  ]
};

const initialAppointmentsDoctorParams: GetAppointmentsParams = {
  offset: 0,
  limit: 15,
  statuses: [AppointmentStatus.Paid, AppointmentStatus.Done]
};

const initialDoctorsParams: ListParams = {
  offset: 0,
  limit: 15
};

const initialPatientsParams: GetPatientsParams = {
  offset: 0,
  limit: 15,
  search: ""
};

const initialAppointmentsAdminParams: GetAppointmentsParams = {
  offset: 0,
  limit: 15,
  order: "ASC",
  orderBy: "date",
  statuses: null,
  doctorId: null,
  pacientId: null,
  specialty: null,
  startAppointmentDate: dayjs().toISOString(),
  insuranceOnly: false
};

const initialState: PaginationState = {
  appointmentsParams: initialAppointmentsParams,
  appointmentsAdminParams: initialAppointmentsAdminParams,
  appointmentsDoctorParams: initialAppointmentsDoctorParams,
  doctorsParams: initialDoctorsParams,
  patientsParams: initialPatientsParams
};

export const paginationSlice = createSlice({
  name: "appointmentsPagination",
  initialState,
  reducers: {
    setAppointmentsParams: (state, action) => {
      state.appointmentsParams = {
        ...state.appointmentsParams,
        ...action.payload
      };
    },
    resetAppointmentsParams: (state) => {
      state.appointmentsParams = initialAppointmentsParams;
    },

    setAppointmentsAdminParams: (state, action) => {
      state.appointmentsAdminParams = {
        ...state.appointmentsAdminParams,
        ...action.payload
      };
    },
    resetAppointmentsAdminParams: (state) => {
      state.appointmentsAdminParams = initialAppointmentsAdminParams;
    },

    setAppointmentsDoctorParams: (state, action) => {
      state.appointmentsAdminParams = {
        ...state.appointmentsAdminParams,
        ...action.payload
      };
    },
    resetAppointmentsDoctorParams: (state) => {
      state.appointmentsAdminParams = initialAppointmentsDoctorParams;
    },

    setDoctorsParams: (state, action) => {
      state.doctorsParams = {
        ...state.doctorsParams,
        ...action.payload
      };
    },
    resetDoctorsParams: (state) => {
      state.doctorsParams = initialDoctorsParams;
    },

    setPatientsParams: (state, action) => {
      state.patientsParams = {
        ...state.patientsParams,
        ...action.payload
      };
    },
    resetPatientsParams: (state) => {
      state.patientsParams = initialPatientsParams;
    },

    resetAll: (state) => {
      state.patientsParams = initialPatientsParams;
      state.doctorsParams = initialDoctorsParams;
      state.appointmentsAdminParams = initialAppointmentsAdminParams;
      state.appointmentsParams = initialAppointmentsParams;
      state.appointmentsParams = initialAppointmentsParams;
    }
  }
});
