import { IonApp, setupIonicReact } from "@ionic/react";
import { FC } from "react";
import { ThemeProvider } from "styled-components";
import { LoadingBar } from "react-redux-loading-bar";
import { IonReactRouter } from "@ionic/react-router";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

import Routes from "@/router";
import "./theme/main.scss";
import { GlobalStyle, theme } from "./theme";
import { AxiosInterceptor } from "./interceptors/axiosInterceptor";
import AppLayout from "./layout/content/appLayout";

if (process.env.REACT_APP_ANALYTICS === "true") {
  ReactGA.initialize(
    [
      {
        trackingId: "G-2XV312Q7PK"
      }
    ],
    { testMode: true }
  );
  TagManager.initialize({
    gtmId: "GTM-N8B53BW"
  });
}

setupIonicReact({
  mode: "md"
});

const history = createBrowserHistory();

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <LoadingBar />
      <AxiosInterceptor />

      <IonApp>
        <IonReactRouter history={history}>
          <AppLayout>
            <Routes />
          </AppLayout>
        </IonReactRouter>
      </IonApp>
    </ThemeProvider>
  );
};

export default App;
