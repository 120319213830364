import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useState
} from "react";
import { TwilioError } from "twilio-video";
import { Api, AxiosClient } from "@/api";
import { AxiosResponse } from "axios";
import { RoomType } from "../types";
import {
  settingsReducer,
  initialSettings,
  Settings,
  SettingsAction
} from "./settings/settingsReducer";
import useActiveSinkId from "../hooks/useActiveSinkId";

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(
    name: string,
    room: string,
    passcode?: string
  ): Promise<AxiosResponse<{ room_type: RoomType; token: string }>>;
  user?:
    | any
    | null
    | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider({
  children
}: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(
    settingsReducer,
    initialSettings
  );
  const [roomType, setRoomType] = useState<RoomType>();

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType
  } as StateContextType;

  contextValue = {
    ...contextValue,
    getToken: async (_, room_name) => {
      const body = {
        roomId: room_name,
        createConversation: true
      };

      return AxiosClient.post(Api.videoCredentials(), body);
    }
  };

  const getToken: StateContextType["getToken"] = (name, room) => {
    setIsFetching(true);

    return contextValue
      .getToken(name, room)
      .then((res) => {
        setRoomType(res.data.room_type);
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const providerContextValue = useMemo(
    () => ({ ...contextValue, getToken }),
    []
  );

  return (
    <StateContext.Provider value={providerContextValue}>
      {children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
