import React, { FC, forwardRef, useEffect, useState } from "react";
import IMask from "imask";
import { IMaskMixin } from "react-imask";
import { IonInput } from "@ionic/react";
import { getStyledElement, getMediaStyles } from "@/helpers";
import { Box } from "../box";
import { ToastedError } from "../toastedError";

const InputSmall = getStyledElement(IonInput)({
  styles: ({ theme }) => ({
    minHeight: "inherit !important",
    "--padding-start": "13px",
    "--padding-end": "13px",
    "--padding-top": "7px",
    "--padding-bottom": "7px",
    "--border-radius": `${theme.borderRadius[10]} !important`,
    "--color": theme.colors.primaryPrx,
    "--background": theme.colors.whitePrx,
    "&.sc-ion-input-md-h": {
      "--border-color": theme.colors.primaryPrx,
      "--highlight-color": theme.colors.primaryPrx,
      "--highlight-color-focused": theme.colors.primaryPrx,
      "--highlight-color-invalid": theme.colors.red74Prx,
      "--highlight-color-valid": theme.colors.primaryPrx
    },
    "&.input-fill-outline.sc-ion-input-md-h:hover": {
      "--border-color": theme.colors.primaryPrx
    },
    ".native-input": {
      lineHeight: 1.5
    },
    ...getMediaStyles({
      fontSize: ["14px", "16px"]
    })
  })
});

type Props = {
  value?: string;
  name?: string;
  error?: string;
  touched?: boolean;
  timeRestriction?: {
    HH: {
      from: number;
      to: number;
    };
    MM: {
      from: number;
      to: number;
    };
  };
  onIonChange?: (event: any) => void;
  onClick?: (event: any) => void;
};

const RefInput = forwardRef((props, ref) => (
  <InputSmall {...props} ref={ref} />
));

const MaskInput = IMaskMixin<any, any>(({ inputRef, ...props }) => (
  <RefInput {...props} ref={inputRef} />
));

const InputTime: FC<Props> = ({
  value,
  name,
  error,
  touched = true,
  timeRestriction = {
    HH: {
      from: 0,
      to: 23
    },
    MM: {
      from: 0,
      to: 59
    }
  },
  onIonChange = () => {},
  onClick = () => {}
}) => {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(!!error);
  }, [error]);

  return (
    <Box position="relative" textAlign="center">
      <MaskInput
        fill="outline"
        className={`${!error && "ion-valid"} ${
          error && touched && "ion-invalid"
        } ${touched && "ion-touched"}`}
        name={name}
        value={value}
        onIonChange={onIonChange}
        onClick={onClick}
        mask="HH:MM"
        overwrite
        autofix
        blocks={{
          HH: {
            mask: IMask.MaskedRange,
            placeholderChar: "HH",
            from: timeRestriction.HH.from,
            to: timeRestriction.HH.to,
            maxLength: 2
          },
          MM: {
            mask: IMask.MaskedRange,
            placeholderChar: "MM",
            from: timeRestriction.MM.from,
            to: timeRestriction.MM.to,
            maxLength: 2
          }
        }}
      />
      {isError && <ToastedError>{error}</ToastedError>}
    </Box>
  );
};

export default React.memo(InputTime);
