import { useAuth } from "@/store/auth/hooks";
import { IonProgressBar } from "@ionic/react";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { AppStorage } from "@/services/storage";
import { Box, VideoDialog } from "@/components";
import { LANGUAGE_KEY } from "@/core/constants";
import i18n from "@/i18n";
import VideoPage from "@/pages/video";
import { DialogProvider } from "@/components/dialog/hooks/useDialog/DialogProvider";
import TabsToolbar from "../../header/ToolbarMobile";

const AppLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { setAuthFromStorage } = useAuth();
  const [isLoadedApp, setIsLoadedApp] = useState(false);

  useEffect(() => {
    (async () => {
      /**
       * @deprecated need to search a place where will be posible to preset app configuration, and init some app data
       */
      await AppStorage.create();
      await setAuthFromStorage().unwrap();
      const lang = await AppStorage.get(LANGUAGE_KEY);
      i18n.changeLanguage(lang);
      setIsLoadedApp(true);
    })();
  }, []);

  if (!isLoadedApp) return <IonProgressBar type="indeterminate" />;

  return (
    <DialogProvider>
      <VideoDialog>
        <VideoPage />
      </VideoDialog>
      <Box position="relative" height="100vh">
        {children}
      </Box>
      <Box position="sticky" bottom="0" left="0" right="0">
        <TabsToolbar />
      </Box>
    </DialogProvider>
  );
};

export default React.memo(AppLayout);
